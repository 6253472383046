@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../../../../shared/scss/_dt-base';

.container {
  background-color: $light-gray;
  padding: 20px 15px;
  margin: 0 -10px;

  @include media($min-sm) {
    margin: 0;
    padding: 20px;
    display: flex;
  }

  @include media($min-md) {
    padding: 36px 40px;
    max-width: 860px;
  }

  @include media($min-xl) {
    max-width: 1130px;
  }
}

.content {
  font-size: 13px;
  margin-bottom: 17px;

  @include media($min-sm) {
    margin-top: -3px;
  }

  @include media($min-xl) {
    max-width: 393px;
  }
}

.tire-details {
  img {
    width: 100%;
    max-width: 333px;
    display: block;
    margin: 0 auto;

    @include media($min-xl) {
      max-width: 244px;
    }
  }

  .details-notice {
    color: $dark-gray;
    font-size: 11px;
    margin-top: 10px;
  }
}

.tire-sidewall-example {
  margin: 0 auto;

  img {
    max-width: 315px;
    width: 100%;
    display: block;
  }

  .title {
    font-size: 12px;
    font-weight: 700;
    margin: 16px 0 5px;
    color: $dark-gray;

    @include media($min-sm) {
      margin-top: -2px;
    }
  }
}

.inside-door-example {
  margin: 0 auto;

  img {
    max-width: 315px;
    width: 100%;
    display: block;
  }

  .title {
    font-size: 12px;
    font-weight: 700;
    margin: 16px 0 5px;
    color: $dark-gray;

    @include media($min-xl) {
      margin-top: -2px;
    }
  }
}

.left-col {
  @include media($min-sm) {
    width: 49%;
    margin-right: 17px;
  }

  @include media($min-xl) {
    width: 33%;
  }
}

.right-col {
  @include media($min-sm) {
    width: 49%;
    margin-left: 17px;
  }

  @include media($min-xl) {
    width: 66%;
    display: flex;

    div:first-of-type {
      margin-right: 20px;
      width: 49%;
    }

    div:last-of-type {
      width: 49%;
      margin-left: 20px;
    }
  }
}
