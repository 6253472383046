@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/_dt-base';

%container {
  background: none;
  display: flex;
  padding: 9px 6px;
  color: $blue-700;

  &:focus {
    outline: 2px solid $gold-500;
  }
}

%icon {
  &:first-child {
    width: 14px;
  }
  height: 14px;
  position: relative;
  top: 5px;
}

.container {
  @extend %container;
  &:hover,
  &:active,
  &:focus {
    color: $blue-700;
  }

  &:disabled {
    color: $grey-500;
  }
}

.primary-container {
  @extend %container;
  justify-content: space-between;
  width: 100%;
  color: $black;
  &:hover,
  &:focus,
  &:active {
    color: $black;
  }

  &:disabled {
    color: $grey-500;
  }
}

.icon-left {
  @extend %icon;
  margin: 2px 8px 0 0;
}

.icon-right {
  @extend %icon;
  margin-left: 8px;
}

.chevron-icon {
  width: 19px;
  height: 19px;
  font-size: rem(32);
  color: $grey-500;
  margin-top: 2px;
}
